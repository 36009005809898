export default [

  // *===============================================---*
  // *--------- SALES ----------------------------------*
  // *===============================================---*
  {
    path: '/pos/sale/list',
    name: 'pos-sale-list',
    component: () => import('@/views/pos/sale/list/SaleList.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },
  {
    path: '/pos/sale/new',
    name: 'pos-sale-new',
    component: () => import('@/views/pos/sale/new-edit/NewSale.vue'),
    meta: {
      resource: 'sale',
      action: 'create',
    },
  },
  {
    path: '/pos/sale/edit/:id',
    name: 'pos-sale-edit',
    component: () => import('@/views/pos/sale/new-edit/EditSale.vue'),
    meta: {
      resource: 'sale',
      action: 'update',
    },
  },
  {
    path: '/pos/sale/view/:id',
    name: 'pos-sale-view',
    component: () => import('@/views/pos/sale/list/ViewSale.vue'),
    meta: {
      resource: 'sale',
      action: 'read',
    },
  },

  // *===============================================---*
  // *--------- CLIENT ---------------------------------*
  // *===============================================---*
  {
    path: '/pos/client/list',
    name: 'pos-client-list',
    component: () => import('@/views/pos/client/list/ClientList.vue'),
    meta: {
      resource: 'client',
      action: 'read',
    },
  },
  {
    path: '/pos/client/new',
    name: 'pos-client-new',
    component: () => import('@/views/pos/client/new-edit/NewClient.vue'),
    meta: {
      resource: 'client',
      action: 'create',
    },
  },
  {
    path: '/pos/client/edit/:id',
    name: 'pos-client-edit',
    component: () => import('@/views/pos/client/new-edit/EditClient.vue'),
    meta: {
      resource: 'client',
      action: 'update',
    },
  },

]
